import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import datesWithCheckup from "./slices/calendarData";
import checkupInfo from "./slices/checkupData";
import pageNavigation from "./slices/navigation";
import userParams from "./slices/userParams";
import moodChartData from "./slices/moodChartData";
import moodFactorsChartData from "./slices/moodFactorsChartData";
import emotionsStatsChartData from "./slices/emotionsStatsChartData";
import customQuestionsData from "./slices/customQuestionsData";
import sharingData from "./slices/sharingData";
const stringMiddleware = () => (next) => (action) => {
  if (typeof action === "string") {
    return next({
      type: action,
    });
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    datesWithCheckup,
    checkupInfo,
    pageNavigation,
    userParams,
    moodChartData,
    moodFactorsChartData,
    emotionsStatsChartData,
    customQuestionsData,
    sharingData,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(stringMiddleware),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
