import "./CheckupSkeleton.scss";
import "../../resources/styles/skeleton.scss";
const CheckupSkeleton = () => {
  return (
    <main className="checkup">
      <div className="checkup-header skeleton"></div>
      <section className="checkup__content">
        <div className="checkup__content__description skeleton"></div>
        <section className="checkup__content__section">
          <div className="checkup__content__item skeleton"></div>
          <div className="checkup__content__item skeleton"></div>
        </section>
        <section className="checkup__content__note">
          <div className="checkup__content__item skeleton"></div>
        </section>
      </section>
    </main>
  );
};

export default CheckupSkeleton;
