export const createGradient = (ctx, area, colors) => {
  const colorAwful = colors[4];
  const colorBad = colors[3];
  const colorAverage = colors[2];
  const colorGood = colors[1];
  const colorPerfect = colors[0];

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorAwful);
  gradient.addColorStop(0.25, colorBad);
  gradient.addColorStop(0.5, colorAverage);
  gradient.addColorStop(0.75, colorGood);
  gradient.addColorStop(1, colorPerfect);

  return gradient;
};

export const setChartGridColor = (
  colorScheme,
  lightShemeColor,
  darkSchemeColor
) => {
  if (colorScheme === "light") {
    return lightShemeColor;
  }
  return darkSchemeColor;
};
