import { FC, useState } from "react";
import * as React from "react";
import "./UserSharedWith.scss";
import { ReactComponent as SettingsIcon } from "../../resources/images/navbarSettings.svg";
import { ReactComponent as CloseIcon } from "../../resources/images/closeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../resources/images/deleteIcon.svg";
import { ReactComponent as CheckMark } from "../../resources/images/checkMark.svg";
import { useDispatch, useSelector } from "react-redux";
import { API, TELEGRAM } from "../../util/constants";
import { revokeShare, updateShareAccess } from "../../store/slices/sharingData";
import { debounce } from "../../util/helpers";
import { throttle } from "../../util/helpers";
import { sendMetrics } from "../../util/metrics";
import Translator from "../../resources/localization/translator";

interface ComponentProps {
  name: string;
  tgUsername: string;
  profilePicFileId: string;
  shareToken: string;
  access: string;
}

const UserSharedWith: FC<ComponentProps> = ({
  name,
  tgUsername,
  profilePicFileId,
  shareToken,
  access,
}) => {
  const { apiToken, language } = useSelector(
    (state: UserParamsSlice) => state.userParams
  );
  const [isPopupOpen, setPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const getModeString = (mode: string): string => {
    switch (mode) {
      case "default":
        return "ONLY_MOOD";
      case "emotions":
        return "EMOTIONS_AND_REASONS";
      case "all":
        return "ALL";
      default:
        return "";
    }
  };

  const handlePopupOpenClick = () => {
    sendMetrics("click-sharing-settings-btn");
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    setPopupOpen(!isPopupOpen);
  };

  const [sharedSelectedCategory, setSharedSelectedCategory] = useState(access); // Значение выбранной категории
  const [isImageLoaded, setIsImageLoaded] = React.useState(true);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageError = () => {
    setIsImageLoaded(false);
  };
  const handleRadioChangeImmediate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSharedSelectedCategory(e.target.value);
  };

  const dispatchData = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateShareAccess({
        apiToken: apiToken,
        shareToken: shareToken,
        newAccess: e.target.value,
      }) as any
    );
  };

  const dispatchRadioData = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatchData(e);
  };

  const throttledHandleRadioChange = debounce(dispatchRadioData, 1000);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    sendMetrics("reachGoal", "click-sharing-settings-for-user-radio");
    handleRadioChangeImmediate(e);
    throttledHandleRadioChange(e);
    TELEGRAM.HapticFeedback.impactOccurred("light");
  };

  const revokeShareHandler = (shareToken: string) => {
    sendMetrics("reachGoal", "click-revoke-share-btn");
    dispatch(
      revokeShare({ apiToken: apiToken, shareToken: shareToken }) as any
    );
    setPopupOpen(false);
  };

  return (
    <div className="user-shared-with">
      <div className="user-shared-with-rights">
        <p className="user-shared-with-rights__text">
          {Translator.translate(getModeString(access), language).toLowerCase()}
        </p>
      </div>
      <div className="user-shared-with__profile">
        <div className="user-shared-with__profile-avatar">
          {isImageLoaded && profilePicFileId && (
            <img
              src={`https://api.monday4.me/get_profile_pic?api_token=${apiToken}&profile_pic_file_id=${profilePicFileId}`}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          )}
          <p className="user-panel__profile-avatar__literal">
            {name ? name[0] : ""}
          </p>
        </div>
        <div className="user-shared-with__profile-info">
          <p className="user-shared-with__profile-info__name">{name}</p>
          <p className="user-shared-with__profile-info__telegram">
            @{tgUsername}
          </p>
        </div>
      </div>
      <SettingsIcon onClick={handlePopupOpenClick} />

      {isPopupOpen && (
        <div className={`popup-overlay ${isPopupOpen ? "open" : ""}`}>
          <div className={`popup-container ${isPopupOpen ? "open" : ""}`}>
            <div className="popup-content">
              <div className="popup-content__header">
                <div className="user-shared-with__profile">
                  <div className="user-shared-with__profile-avatar">
                    {isImageLoaded && profilePicFileId && (
                      <img
                        src={`https://api.monday4.me/get_profile_pic?api_token=${apiToken}&profile_pic_file_id=${profilePicFileId}`}
                        onLoad={handleImageLoad}
                        onError={handleImageError}
                      />
                    )}
                    <p className="user-panel__profile-avatar__literal">
                      {name ? name[0] : ""}
                    </p>
                  </div>
                  <div className="user-shared-with__profile-info">
                    <p className="user-shared-with__profile-info__name">
                      {name}
                    </p>
                    <p className="user-shared-with__profile-info__telegram">
                      @{tgUsername}
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="popup-content__close"
                onClick={handlePopupOpenClick}
              >
                <CloseIcon />
              </button>
              <div className="sharing-page-categories border-bottom">
                <div className="sharing-page-categories__item">
                  <input
                    type="radio"
                    id="popup_radio1"
                    name="popup_category"
                    value="default"
                    onChange={handleRadioChange}
                    checked={sharedSelectedCategory === "default"}
                  />
                  {/* <div className="sharing-page-categories-info"> */}
                  <label
                    htmlFor="popup_radio1"
                    className="sharing-page-categories-info__label"
                  >
                    {Translator.translate("ONLY_MOOD", language)}
                  </label>
                  <CheckMark className="radio-icon" />
                  {/* </div> */}
                </div>

                <div className="sharing-page-categories__item">
                  <input
                    type="radio"
                    id="popup_radio2"
                    name="popup_category"
                    value="emotions"
                    onChange={handleRadioChange}
                    checked={sharedSelectedCategory === "emotions"}
                  />
                  {/* <div className="sharing-page-categories-info"> */}
                  <label
                    htmlFor="popup_radio2"
                    className="sharing-page-categories-info__label"
                  >
                    {Translator.translate("EMOTIONS_AND_REASONS", language)}
                  </label>
                  <CheckMark className="radio-icon" />
                  {/* </div> */}
                </div>

                <div className="sharing-page-categories__item">
                  <input
                    type="radio"
                    id="popup_radio3"
                    name="popup_category"
                    value="all"
                    onChange={handleRadioChange}
                    checked={sharedSelectedCategory === "all"}
                  />
                  {/* <div className="sharing-page-categories-info"> */}
                  <label
                    htmlFor="popup_radio3"
                    className="sharing-page-categories-info__label"
                  >
                    {Translator.translate("ALL", language)}
                  </label>
                  <CheckMark className="radio-icon" />
                  {/* </div> */}
                </div>
              </div>
              <div className="popup-content__footer">
                <button className="popup-content__footer-btn">
                  {Translator.translate("DELETE", language)}
                </button>
                {/* <DeleteIcon onClick={() => revokeShareHandler(shareToken)} /> */}
                <DeleteIcon
                  onClick={() => {
                    TELEGRAM.HapticFeedback.impactOccurred("heavy");
                    TELEGRAM.showPopup(
                      {
                        message: `${Translator.translate(
                          "DELETE_USER_CONFIRMATION",
                          language
                        )} @${tgUsername}?`,
                        buttons: [
                          {
                            id: "cancel",
                            type: "cancel",
                            text: Translator.translate("CANCEL", language),
                          },
                          {
                            id: "delete",
                            type: "destructive",
                            text: Translator.translate("DELETE", language),
                          },
                        ],
                      },
                      (buttonId) => {
                        if (buttonId === "delete")
                          revokeShareHandler(shareToken);
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSharedWith;
