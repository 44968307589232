import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../util/constants";
import { AxiosResponse } from "axios";

const initialState: SharingData = {
  viewableDiaries: {},
  viewableDiariesLoadingStatus: "idle",
  diaryViewers: {},
  diaryViewersLoadingStatus: "idle",
  access: {},
  accessLoadingStatus: "idle",
  diaryOwnerPicId: "",
  diaryOwnerName: "",
  diaryOwnerTgUsername: "",
  activeUserPicId: "",
  activeUserName: "",
  activeUserTgUsername: "",
  activeProperties: "",
  revokeShare: {},
  revokeShareLoadingStatus: "idle",
  inviteLink: {},
  inviteLinkLoadingStatus: "idle",
  isOwnerDiary: true,
  isUserLoaded: false,
};

export const fetchViewableDiaries = createAsyncThunk(
  "userParams/fetchViewableDiaries",
  async ({ apiToken }: { apiToken: string }, { rejectWithValue }) => {
    try {
      const response =
        (await API.getViewableDiaries.getViewableDiariesGetViewableDiariesGet({
          api_token: apiToken,
        })) as AxiosResponse<FetchViewableDiariesResponse>;
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchDiaryViewers = createAsyncThunk(
  "userParams/fetchDiaryViewers",
  async ({ apiToken }: { apiToken: string }, { rejectWithValue }) => {
    try {
      const response =
        (await API.getDiaryViewers.getDiaryViewersGetDiaryViewersGet({
          api_token: apiToken,
        })) as AxiosResponse<FetchDiaryViewersResponse>;
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);
export const updateShareAccess = createAsyncThunk(
  "userParams/updateShareAccess",
  async (
    {
      apiToken,
      shareToken,
      newAccess,
    }: { apiToken: string; shareToken: string; newAccess: string },
    { rejectWithValue }
  ) => {
    try {
      const response =
        (await API.updateShareAccess.updateShareAccessCbUpdateShareAccessGet({
          api_token: apiToken,
          share_token: shareToken,
          new_access: newAccess,
        })) as AxiosResponse<ShareAccessObject>;
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);

export const revokeShare = createAsyncThunk(
  "userParams/revokeShare",
  async (
    { apiToken, shareToken }: { apiToken: string; shareToken: string },
    { rejectWithValue }
  ) => {
    try {
      const response = (await API.revokeShare.revokeShareRevokeShareGet({
        api_token: apiToken,
        share_token: shareToken,
      })) as AxiosResponse<RevokeShare>;
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchInviteLink = createAsyncThunk(
  "userParams/fetchInviteLink",
  async ({ apiToken }: { apiToken: string }, { rejectWithValue }) => {
    try {
      const response = (await API.getShareToken.getShareLinkGetShareTokenGet({
        api_token: apiToken,
      })) as AxiosResponse<InviteLinkObject>;
      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);

const SharingDataSlice = createSlice({
  name: "sharingData",
  initialState,
  reducers: {
    setDiaryOwnerPicId: (state, { payload }) => {
      state.diaryOwnerPicId = payload;
    },
    setDiaryOwnerName: (state, { payload }) => {
      state.diaryOwnerName = payload;
    },
    setDiaryOwnerTgUsername: (state, { payload }) => {
      state.diaryOwnerTgUsername = payload;
    },
    setActiveUserPicId: (state, { payload }) => {
      state.activeUserPicId = payload;
    },
    setActiveUserName: (state, { payload }) => {
      state.activeUserName = payload;
    },
    setActiveUserTgUsername: (state, { payload }) => {
      state.activeUserTgUsername = payload;
    },
    setActiveProperties: (state, { payload }) => {
      state.activeProperties = payload;
    },
    setIsOwnerDiary: (state, { payload }) => {
      state.isOwnerDiary = payload;
    },
    setIsUserLoaded: (state, { payload }) => {
      state.isUserLoaded = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchViewableDiaries.pending, (state) => {
        state.viewableDiariesLoadingStatus = "loading";
      })
      .addCase(fetchViewableDiaries.fulfilled, (state, action) => {
        state.viewableDiariesLoadingStatus = "idle";
        state.viewableDiaries = action.payload;
      })
      .addCase(fetchViewableDiaries.rejected, (state) => {
        state.viewableDiariesLoadingStatus = "error";
      })
      ////
      .addCase(fetchDiaryViewers.pending, (state) => {
        state.diaryViewersLoadingStatus = "loading";
      })
      .addCase(fetchDiaryViewers.fulfilled, (state, action) => {
        state.diaryViewersLoadingStatus = "idle";
        state.diaryViewers = action.payload;
      })
      .addCase(fetchDiaryViewers.rejected, (state) => {
        state.diaryViewersLoadingStatus = "error";
      })
      ////
      .addCase(updateShareAccess.pending, (state) => {
        state.accessLoadingStatus = "loading";
      })
      .addCase(updateShareAccess.fulfilled, (state, action) => {
        state.accessLoadingStatus = "idle";
        state.access = action.payload;
      })
      .addCase(updateShareAccess.rejected, (state) => {
        state.accessLoadingStatus = "error";
      })
      ////
      .addCase(revokeShare.pending, (state) => {
        state.revokeShareLoadingStatus = "loading";
      })
      .addCase(revokeShare.fulfilled, (state, action) => {
        state.revokeShareLoadingStatus = "idle";
        state.revokeShare = action.payload;
      })
      .addCase(revokeShare.rejected, (state) => {
        state.revokeShareLoadingStatus = "error";
      })
      ////все аддкейсы для инвайтлинка
      .addCase(fetchInviteLink.pending, (state) => {
        state.inviteLinkLoadingStatus = "loading";
      })
      .addCase(fetchInviteLink.fulfilled, (state, action) => {
        state.inviteLinkLoadingStatus = "idle";
        state.inviteLink = action.payload;
      })
      .addCase(fetchInviteLink.rejected, (state) => {
        state.inviteLinkLoadingStatus = "error";
      });
  },
});

const { actions, reducer } = SharingDataSlice;

export default reducer;
export const {
  setActiveUserPicId,
  setDiaryOwnerPicId,
  setActiveUserName,
  setActiveUserTgUsername,
  setDiaryOwnerName,
  setDiaryOwnerTgUsername,
  setActiveProperties,
  setIsOwnerDiary,
  setIsUserLoaded,
} = actions;
