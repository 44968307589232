import { useSelector } from "react-redux";
import * as React from "react";

const ViewableUser = ({ diary }) => {
  const { apiToken } = useSelector(
    (state: UserParamsSlice) => state.userParams
  );
  const { viewableDiaries } = useSelector(
    (state: SharingDataSlice) => state.sharingData
  );
  const [isImageLoaded, setIsImageLoaded] = React.useState(true);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageError = () => {
    setIsImageLoaded(false);
  };
  return (
    <div className="viewable-diaries-popup__profile-user">
      <div className="viewable-diaries-popup__profile-avatar">
        {isImageLoaded && diary.profile_pic_file_id && (
          <img
            src={`https://api.monday4.me/get_profile_pic?api_token=${apiToken}&profile_pic_file_id=${diary.profile_pic_file_id}`}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        )}

        <p className="viewable-diaries-popup__profile-avatar__literal">
          {diary.name ? diary.name[0] : ""}
        </p>
      </div>
      <div className="viewable-diaries-popup__info">
        <div className="viewable-diaries-popup__info-name">
          {viewableDiaries && "diaries" in viewableDiaries && diary.name}
        </div>
        <div className="viewable-diaries-popup__info-telegram">
          {viewableDiaries &&
            "diaries" in viewableDiaries &&
            `@${diary.tg_username}`}
        </div>
      </div>
    </div>
  );
};
export default ViewableUser;
