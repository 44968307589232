import { Link } from "react-router-dom";
import {
  areDatesEqual,
  areDatesEqualObj,
  getISODate,
  getLocalMonthNames,
} from "../../util/dates";
import { WEEK_DAY_NAMES, EMOTION_PICS, TELEGRAM } from "../../util/constants";
import Translator from "../../resources/localization/translator";
import isAfter from "date-fns/isAfter";
import chatGPTIcon from "../../resources/images/chatGPTIcon.svg";
import manyNotes from "../../resources/images/manyNotesIcon.svg";
import fewNotes from "../../resources/images/fewNotesIcon.svg";
import wisdomIcon from "../../resources/images/wisdomIcon.svg";
import { useSelector } from "react-redux";
import cn from "classnames";
import { startCheckup } from "../../util/helpers";
import { sendMetrics } from "../../util/metrics";

const SingleMonthView = ({
  date,
  monthData,
  datesArr,
  scrollHandler,
  apiToken,
}) => {
  const { language, shareToken } = useSelector((state) => state.userParams);
  const { activeProperties } = useSelector((state) => state.sharingData);
  const renderDay = (date, index) => {
    if (!date) return <div key={index} className="empty-block" />;

    const dayInfo = areDatesEqualObj(date, datesArr);

    const mood = dayInfo && dayInfo.mood;
    const hasNote = dayInfo && dayInfo.notes_count;
    const hasAIWisdom = dayInfo && dayInfo.has_ai_wisdom;
    const hasWisdom = dayInfo && dayInfo.has_wisdom;

    const dayInfoHandler = (
      checkNote,
      baseImg,
      secondaryImg = null,
      isNumericValue = false
    ) => {
      if (!checkNote) return;
      if (isNumericValue && checkNote === 1) {
        return <img src={secondaryImg} alt="dayInfo" />;
      }
      return <img src={baseImg} alt="dayInfo" />;
    };
    const startCheckupFromMainPage = () => {
      if (startCheckupFromMainPage.cantClick) return;
      startCheckupFromMainPage.cantClick = true;
      sendMetrics("reachGoal", "click-start-checkup-main-page-btn");
      startCheckup(apiToken, getISODate(new Date()));
      setTimeout(() => {
        TELEGRAM.close();
      }, 0);
      setTimeout(() => {
        startCheckupFromMainPage.cantClick = false;
      }, 500);
    };

    const renderImage = () => {
      if (EMOTION_PICS[mood]) {
        return <img src={EMOTION_PICS[mood]} alt="mood" />;
      }
      if (isToday) {
        return (
          <>
            {!shareToken ? (
              <button onClick={startCheckupFromMainPage}>
                <div className="today-svg">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="18"
                      cy="18"
                      r="17.5"
                      fill="var(--tg-theme-bg-color, #eeeef8)"
                      stroke="var(--tg-theme-button-color, #7868e6)"
                    />
                    <line
                      x1="18.5"
                      y1="10"
                      x2="18.5"
                      y2="26"
                      stroke="var(--tg-theme-button-color, #7868e6)"
                    />
                    <line
                      x1="10"
                      y1="17.5"
                      x2="26"
                      y2="17.5"
                      stroke="var(--tg-theme-button-color, #7868e6)"
                    />
                  </svg>
                </div>
              </button>
            ) : (
              <div className="table-cell__img" />
            )}
          </>
        );
      }

      return <div className="table-cell__img" />;
    };
    const setDayStyle = () => {
      if (isAfter(date, new Date()))
        return (
          <div className="table-cell-wrapper">
            <div className="table-cell__date">{date.getDate()}</div>
            <div className="empty-block"></div>
          </div>
        );
      if (dayInfo) {
        return (
          <>
            {activeProperties !== "default" ? (
              <Link
                to={`/checkup/${getISODate(date)}`}
                onClick={() => scrollHandler()}
                className="table-cell-wrapper"
              >
                <div
                  className={cn("table-cell__date", {
                    "table-cell__date_today": isToday,
                  })}
                >
                  {date.getDate()}
                </div>
                {renderImage()}
                <div className="table-cell__hasNote">
                  {dayInfoHandler(hasNote, manyNotes, fewNotes, true)}
                  {dayInfoHandler(hasAIWisdom, chatGPTIcon)}
                  {dayInfoHandler(hasWisdom, wisdomIcon)}
                </div>
              </Link>
            ) : (
              <div>
                <div
                  className={cn("table-cell__date", {
                    "table-cell__date_today": isToday,
                  })}
                >
                  {date.getDate()}
                </div>
                {renderImage()}
                <div className="table-cell__hasNote">
                  {dayInfoHandler(hasNote, manyNotes, fewNotes, true)}
                  {dayInfoHandler(hasAIWisdom, chatGPTIcon)}
                  {dayInfoHandler(hasWisdom, wisdomIcon)}
                </div>
              </div>
            )}
          </>
        );
      }
      if (isToday) {
        return (
          <div>
            <div
              className={cn("table-cell__date", {
                "table-cell__date_today": isToday,
              })}
            >
              {date.getDate()}
            </div>
            {renderImage()}
            <div className="table-cell__hasNote">
              {dayInfoHandler(hasNote, manyNotes, fewNotes, true)}
              {dayInfoHandler(hasAIWisdom, chatGPTIcon)}
            </div>
          </div>
        );
      }
      return (
        <>
          {activeProperties !== "default" ? (
            <Link
              to={`/checkup/${getISODate(date)}`}
              onClick={() => scrollHandler()}
              className="table-cell-wrapper"
            >
              <div className="table-cell__date">{date.getDate()}</div>
              <div className="table-cell__img"></div>
            </Link>
          ) : (
            <div>
              <div className="table-cell__date">{date.getDate()}</div>
              <div className="table-cell__img"></div>
            </div>
          )}
        </>
      );
    };
    const isToday = areDatesEqual(date, new Date());

    return (
      <div className="table-cell" key={index}>
        {setDayStyle()}
      </div>
    );
  };

  return (
    <div className="single-month-wrapper">
      <div className="single-month">
        <div className="single-month__header">
          {Translator.translate(getLocalMonthNames(date), language)}{" "}
          {date.getFullYear()}
        </div>
        <div className="single-month-table">
          <div className="single-month-table__head">
            {WEEK_DAY_NAMES.map((name) => (
              <div className="single-month-table__head__week-days" key={name}>
                {Translator.translate(name, language)}
              </div>
            ))}
          </div>
          <div className="single-month-table__body">
            {monthData.map((week, index) => (
              <div key={index} className="single-month-table__body__week">
                {week.map((date, index) => renderDay(date, index))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMonthView;
