import React, { useState } from "react";
import SingleMonthView from "../SingleMonthView";
import "./SingleMonth.scss";
import { useDispatch, useSelector } from "react-redux";
import { getMonthData } from "../../util/dates";
import { setScrollPosition } from "../../store/slices/calendarData";
import SingleMonthSkeleton from "../SingleMonthSkeleton";
import Translator from "../../resources/localization/translator";
import { TELEGRAM } from "../../util/constants";
import { sendMetrics } from "../../util/metrics";

const SingleMonth = ({ propsDate = new Date(), apiToken }) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(propsDate);
  const { datesWithCheckup, datesWithCheckupLoadingStatus, target, language } =
    useSelector((state) => state.datesWithCheckup);

  const handleScroll = () => {
    //const value = target.scrollTop;
    TELEGRAM.HapticFeedback.impactOccurred("light");
    const value = document.documentElement.scrollTop;
    dispatch(setScrollPosition(value));
    sendMetrics("reachGoal", "click-date-btn");
  };

  const monthData = getMonthData(date?.getFullYear(), date?.getMonth());

  const setContent = (process) => {
    if (process === "loading") {
      return <SingleMonthSkeleton />;
    }
    if (process === "error") {
      return <h5>{Translator.translate("LOADING_ERROR", language)}</h5>;
    }
    return (
      <SingleMonthView
        date={date}
        monthData={monthData}
        datesArr={datesWithCheckup}
        datesWithCheckupLoadingStatus={datesWithCheckupLoadingStatus}
        scrollHandler={handleScroll}
        apiToken={apiToken}
      />
    );
  };
  return setContent(datesWithCheckupLoadingStatus);
};

export default SingleMonth;
