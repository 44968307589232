import { ApiClient } from "../api/api-client";

import awfulEmotionPic from "../resources/images/awful.svg";
import badEmotionPic from "../resources/images/bad.svg";
import averageEmotionPic from "../resources/images/average.svg";
import goodEmotionPic from "../resources/images/good.svg";
import perfectEmotionPic from "../resources/images/perfect.svg";

export const TELEGRAM = window.Telegram.WebApp;
export const MAIN_BUTTON = TELEGRAM.MainButton;
export const WEEK_DAY_NAMES = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const DAYS_IN_WEEK = 7;

export const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const WEEK_DAYS_FROM_MONDAY = [6, 0, 1, 2, 3, 4, 5];

export const MONTHS = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  Novermber: 10,
  December: 11,
};

export const API = new ApiClient({
  baseURL: "",
});

export const LOCALE = "ru";

export const EMOTION_PICS = {
  awful: awfulEmotionPic,
  bad: badEmotionPic,
  average: averageEmotionPic,
  good: goodEmotionPic,
  perfect: perfectEmotionPic,
};

////CHARTS

export const MOOD_CHART_COLORS = [
  "#B7D58B",
  "#CBD58B",
  "#FCE770",
  "#EC9696",
  "#F18686",
];

export const MOOD_CHART_COLORS_REVERSE = [
  "#F18686",
  "#EC9696",
  "#FCE770",
  "#CBD58B",
  "#B7D58B",
];
