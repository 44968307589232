import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: "calendar",
};
const NavigationSlice = createSlice({
  name: "pageNavigation",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
});

const { actions, reducer } = NavigationSlice;

export default reducer;
export const { setCurrentPage } = actions;
