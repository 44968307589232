import { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmotionStatsData } from "../../store/slices/emotionsStatsChartData";
import { ReactComponent as StatsIcon } from "../../resources/images/statsIcon.svg";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../../pages/ChartPage/ChartPage.scss";
import Translator from "../../resources/localization/translator";
const EmotionsStatsChart = ({ dateRange }) => {
  const { chartDate, checkupsTotal } = useSelector(
    (state) => state.moodChartData
  );
  const { emotionsStatsData, emotionsStatsDataLoadingStatus } = useSelector(
    (state) => state.emotionsStatsChartData
  );
  const { themeParams, apiToken, language } = useSelector(
    (state) => state.userParams
  );
  const dispatch = useDispatch();
  const chartContainerRef = useRef(null);

  useEffect(() => {
    dispatch(
      fetchEmotionStatsData({
        chartDate: chartDate,
        dateRange: dateRange,
        apiToken: apiToken,
        lang: language,
      })
    );
  }, [chartDate, dateRange, apiToken]);

  function sortByCountDescending(data) {
    if (Array.isArray(data)) {
      const sortedData = [...data];

      sortedData.sort((a, b) => {
        const countA = Array.isArray(a.factorB_stats)
          ? a.factorB_stats.reduce((sum, item) => sum + item.count, 0)
          : a.factorB_stats.count;
        const countB = Array.isArray(b.factorB_stats)
          ? b.factorB_stats.reduce((sum, item) => sum + item.count, 0)
          : b.factorB_stats.count;
        return countB - countA;
      });

      return sortedData;
    }
  }

  const sortedData = sortByCountDescending(emotionsStatsData);

  function generateBarChartDataset(data, visibleCount) {
    const visibleData = data?.slice(0, visibleCount);
    const labels = [];
    const counts = [];
    const colors = [];

    visibleData?.forEach((item) => {
      const { factorA_name, factorA_positive, factorB_stats } = item;
      const label = factorA_name;
      let count = 0;

      if (Array.isArray(factorB_stats)) {
        count = factorB_stats.reduce((sum, item) => sum + item.count, 0);
      } else {
        count = factorB_stats.count;
      }

      const color = factorA_positive ? "#B7D58B" : "#F18686";

      labels.push(label);
      counts.push(count);
      colors.push(color);
    });

    const dataset = {
      labels: labels,
      datasets: [
        {
          label: "Count",
          data: counts,
          backgroundColor: colors,
          barThickness: 20,
          borderWidth: 0,
          borderRadius: {
            topLeft: 5,
            topRight: 5,
            bottomLeft: 5,
            bottomRight: 5,
          },
          borderSkipped: false,
          datalabels: {
            anchor: "center",
            align: "center",
            color: "#fff",
          },
        },
      ],
    };

    return dataset;
  }

  const mockDataset = {
    labels: ["Радость", "Грусть", "Уверенность", "Усталость"],
    datasets: [
      {
        label: "Count",
        data: [10, 6, 4, 2],
        backgroundColor: ["#B7D58B", "#F18686", "#B7D58B", "#F18686"],
        barThickness: 20,
        borderWidth: 0,
        borderRadius: {
          topLeft: 5,
          topRight: 5,
          bottomLeft: 5,
          bottomRight: 5,
        },
        datalabels: {
          anchor: "center",
          align: "center",
          color: "#fff",
        },
      },
    ],
  };

  const initialVisibleCount = 4;
  const [visibleCount, setVisibleCount] = useState(initialVisibleCount);
  const [showAll, setShowAll] = useState(false);
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    setDataLength(emotionsStatsData.length);
  }, [emotionsStatsData]);

  const toggleVisibility = () => {
    if (emotionsStatsData.length === 0) return;
    setShowAll(!showAll);
    setVisibleCount(showAll ? initialVisibleCount : data.length);
  }; // количество видимых столбцов

  const data =
    emotionsStatsData.length === 0
      ? mockDataset
      : generateBarChartDataset(sortedData, visibleCount);

  const options = {
    maintainAspectRatio: false,
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      tooltip: { enabled: false },
      datalabels: {
        font: {
          family: "manrope",
          size: 12,
          weight: "bold",
        },
        formatter: function (value) {
          if (value === 0) {
            return "";
          }
          return value;
        },
      },
      legend: {
        display: false, // скрываем легенду
      },
      title: {
        display: false, // скрываем заголовок
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false, // не отображаем координатную сетку
        },
        ticks: {
          display: true,
          stepSize: 1,
          font: {
            color: themeParams.text_color,
            family: "Manrope",
            style: "normal",
            weight: 700,
            size: 14,
          },
          color: themeParams.text_color,
          crossAlign: "far",
        },
      },
      x: {
        max: Math.max(...data.datasets[0].data),
        beginAtZero: true,
        display: false,
        stepSize: 1,
        grid: {
          display: false,
          drawBorder: false, // не отображаем координатную сетку
        },
      },
    },
  };

  useEffect(() => {
    // Обновление высоты холста при изменении размеров контейнера
    const updateChartHeight = () => {
      if (chartContainerRef.current) {
        const barCount = data.labels.length;
        const barHeight = 35;
        const chartHeight = barCount * barHeight;

        chartContainerRef.current.style.height = `${chartHeight}px`;
      }
    };

    updateChartHeight();
    window.addEventListener("resize", updateChartHeight);

    return () => {
      window.removeEventListener("resize", updateChartHeight);
    };
  }, [data]);
  const containerClassName = `container ${showAll ? "expand" : ""}`;

  const renderChart = () => {
    if (emotionsStatsDataLoadingStatus === "loading") {
      return (
        <section className={`mood-chart-skeleton_${dateRange}`}>
          <div className={`mood-chart-skeleton_${dateRange}-container`}>
            <div className={`mood-chart-skeleton_${dateRange}`}>
              <div
                className={`mood-chart-skeleton_${dateRange} skeleton`}
              ></div>
            </div>
          </div>
        </section>
      );
    }
    if (emotionsStatsDataLoadingStatus === "idle")
      return (
        <div>
          <div
            ref={chartContainerRef}
            style={{ width: "100%" }}
            className={containerClassName}
          >
            <Bar options={options} data={data} plugins={[ChartDataLabels]} />
          </div>
          <button
            className={`show-more-btn${dataLength > 4 ? "" : "_hidden"}`}
            onClick={toggleVisibility}
          >
            {Translator.translate(
              showAll ? "HIDE_MORE" : "SHOW_MORE",
              language
            )}
          </button>
        </div>
      );
  };

  return (
    <div className={`chart-wrapper${checkupsTotal < 2 ? "_empty" : ""}`}>
      {emotionsStatsDataLoadingStatus === "idle" ? (
        <p className={`chart-text${checkupsTotal < 2 ? "_empty" : ""}`}>
          Чтобы увидеть статистику, необходимо пройти хотя бы 2 чекапа
        </p>
      ) : null}
      {emotionsStatsDataLoadingStatus === "idle" ? (
        <div
          className={`chart-page-description${
            checkupsTotal < 2 ? "_blur" : ""
          }`}
        >
          <div className="chart-page-description__content">
            <div className="chart-page-description__emoji">
              <StatsIcon />
            </div>
            <p className="chart-page-description__title">
              {Translator.translate("PREVAILED_EMOTIONS", language)}
            </p>
          </div>
        </div>
      ) : null}
      <div
        className={
          emotionsStatsDataLoadingStatus === "idle"
            ? `mood-chart-container${checkupsTotal < 2 ? "_blur" : ""}`
            : null
        }
      >
        {renderChart()}
      </div>
    </div>
  );
};
export default EmotionsStatsChart;
