import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Translator from "../../../resources/localization/translator";
import { setCurrentPage } from "../../../store/slices/navigation";
import { API, TELEGRAM } from "../../../util/constants";
import { sendMetrics } from "../../../util/metrics";
import "./PageNavigation.scss";
import { setQueryToggle } from "../../../store/slices/calendarData";

const PageNavigation = () => {
  const dispatch = useDispatch();
  const { apiToken, language, viewedByAdmin, shareToken } = useSelector(
    (state) => state.userParams
  );
  const { datesWithCheckup } = useSelector((state) => state.datesWithCheckup);
  const { currentPage } = useSelector((state) => state.pageNavigation);

  const { isOwnerDiary } = useSelector((state) => state.sharingData);

  const openSettings = async (data) => {
    try {
      const response =
        await API.openDiarySettings.openDiarySettingsPostOpenDiarySettingsPost({
          api_token: apiToken,
        });

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return error;
    }
  };
  const sendData = () => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    sendMetrics("reachGoal", "click-settings-btn");
    if (!viewedByAdmin) {
      openSettings();
    }
    setTimeout(() => {
      TELEGRAM.close();
    }, 0);
  };
  const clickHandler = () => {
    if (clickHandler.cantClick) return;
    clickHandler.cantClick = true;

    sendData();

    setTimeout(() => {
      clickHandler.cantClick = false;
    }, 500);
  };
  const setActivePage = (page) => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    dispatch(setCurrentPage(page));
    switch (page) {
      case "calendar":
        if (Object.keys(datesWithCheckup).length !== 0) {
          dispatch(setQueryToggle(false));
        }
        sendMetrics("reachGoal", "click-nav-calendar-btn");
        break;
      case "statistics":
        sendMetrics("reachGoal", "click-nav-statistics-btn");
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isOwnerDiary && (
        <div className="navigation">
          <Link
            to={`/calendar/?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button
              className={`navigation__item${
                currentPage === "calendar" ? "_active" : ""
              }`}
              onClick={() => setActivePage("calendar")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" />
                <path
                  d="M10.1053 24.4211C9.6421 24.4211 9.24547 24.2563 8.91537 23.9267C8.58582 23.5966 8.42105 23.2 8.42105 22.7368V10.9474C8.42105 10.4842 8.58582 10.0879 8.91537 9.75832C9.24547 9.42821 9.6421 9.26316 10.1053 9.26316H10.9474V7.57895H12.6316V9.26316H19.3684V7.57895H21.0526V9.26316H21.8947C22.3579 9.26316 22.7545 9.42821 23.0846 9.75832C23.4142 10.0879 23.5789 10.4842 23.5789 10.9474V22.7368C23.5789 23.2 23.4142 23.5966 23.0846 23.9267C22.7545 24.2563 22.3579 24.4211 21.8947 24.4211H10.1053ZM10.1053 22.7368H21.8947V14.3158H10.1053V22.7368Z"
                  fill="#F5F5F5"
                />
              </svg>
              {Translator.translate("CALENDAR_BUTTON", language)}
            </button>
          </Link>
          <Link
            to={`/statistics/?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`}
          >
            <button
              className={`navigation__item${
                currentPage === "statistics" ? "_active" : ""
              }`}
              onClick={() => setActivePage("statistics")}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="32" height="32" rx="5" fill="#F8F8F8" />
                <rect
                  className="plot"
                  x="6"
                  y="6"
                  width="6"
                  height="19"
                  fill="#7868E6"
                />
                <rect
                  className="plot"
                  x="13"
                  y="14"
                  width="6"
                  height="11"
                  fill="#7868E6"
                />
                <rect
                  className="plot"
                  x="20"
                  y="9"
                  width="6"
                  height="16"
                  fill="#7868E6"
                />
              </svg>
              {Translator.translate("STATISTICS_BUTTON", language)}
            </button>
          </Link>
          <button className="navigation__item" onClick={clickHandler}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="5" fill="#F8F8F8" />
              <path
                d="M14.1949 24.4211L13.8667 21.7263C13.6889 21.6561 13.5212 21.5719 13.3637 21.4737C13.2062 21.3754 13.0524 21.2702 12.9026 21.1579L10.4615 22.2105L8.20513 18.2105L10.3179 16.5684C10.3043 16.4702 10.2974 16.3753 10.2974 16.2838V15.7162C10.2974 15.6247 10.3043 15.5298 10.3179 15.4316L8.20513 13.7895L10.4615 9.78948L12.9026 10.8421C13.053 10.7298 13.2103 10.6246 13.3744 10.5263C13.5385 10.4281 13.7026 10.3439 13.8667 10.2737L14.1949 7.57895H18.7077L19.0359 10.2737C19.2137 10.3439 19.3813 10.4281 19.5389 10.5263C19.6964 10.6246 19.8501 10.7298 20 10.8421L22.441 9.78948L24.6974 13.7895L22.5846 15.4316C22.5983 15.5298 22.6051 15.6247 22.6051 15.7162V16.2838C22.6051 16.3753 22.5915 16.4702 22.5641 16.5684L24.6769 18.2105L22.4205 22.2105L20 21.1579C19.8496 21.2702 19.6923 21.3754 19.5282 21.4737C19.3641 21.5719 19.2 21.6561 19.0359 21.7263L18.7077 24.4211H14.1949ZM16.4923 18.9474C17.2855 18.9474 17.9624 18.6597 18.5231 18.0842C19.0838 17.5088 19.3641 16.814 19.3641 16C19.3641 15.186 19.0838 14.4912 18.5231 13.9158C17.9624 13.3404 17.2855 13.0526 16.4923 13.0526C15.6855 13.0526 15.005 13.3404 14.4509 13.9158C13.8968 14.4912 13.62 15.186 13.6205 16C13.6205 16.814 13.8973 17.5088 14.4509 18.0842C15.0044 18.6597 15.6849 18.9474 16.4923 18.9474Z"
                fill="#DAD9FF"
              />
            </svg>
            {Translator.translate("SETTINGS_BUTTON", language)}
          </button>
        </div>
      )}
    </>
  );
};
export default PageNavigation;
