import "./SingleMonthSkeleton.scss";
import "../../resources/styles/skeleton.scss";
const SingleMonthSkeleton = () => {
  return (
    <section className="single-month-skeleton">
      <div className="month-container">
        <div className="month">
          <div className="month-title skeleton"></div>
          <div className="month-img skeleton"></div>
        </div>
      </div>
    </section>
  );
};
export default SingleMonthSkeleton;
