import { LOCALE } from "../../util/constants";

import translationEN from "./locales/en/translation.json";
import translationRU from "./locales/ru/translation.json";

// the translations
const translations = {
  en: translationEN,
  ru: translationRU,
};

class Translator {
  static key = LOCALE;
  static rtlLanguages = ["ar"];
  static defaultLanguage = "en";

  static getLanguage = () => {
    return localStorage.getItem(this.key);
  };

  static setLanguage = (language) => {
    const item = this.key;
    localStorage.setItem(item, language);
  };

  static getBrowserLanguage = () =>
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  static getDefaultLanguage = () => {
    //get the language from localstorage
    const savedLanguage = this.getLanguage();

    //get the language set in the browser
    const browserLanguageCode = Translator.getBrowserLanguage().toLowerCase();
    let browserLanguage = browserLanguageCode;

    //check if the language set in the browser has hyphen(-), if yes split and take the first element of the array
    if (
      browserLanguageCode !== "zh-tw" &&
      browserLanguageCode.indexOf("-") !== -1
    ) {
      const browserLanguageArray = browserLanguageCode.split("-");
      browserLanguage = browserLanguageArray[0];
    }

    //if there is language set in localstorage and it is different from browser language, update local storage and return the language code
    if (savedLanguage) {
      if (savedLanguage !== browserLanguage) {
        this.setLanguage(browserLanguage);

        //if the translations are not available, default to en
        return translations.hasOwnProperty(browserLanguage)
          ? browserLanguage
          : this.defaultLanguage;
      } else {
        //if the translations are not available, default to en
        return translations.hasOwnProperty(browserLanguage)
          ? browserLanguage
          : this.defaultLanguage;
      }
    } else {
      this.setLanguage(browserLanguage);

      //if the translations are not available, default to en
      return translations.hasOwnProperty(browserLanguage)
        ? browserLanguage
        : this.defaultLanguage;
    }
  };

  static getDirection(language) {
    return this.rtlLanguages.includes(language) ? "rtl" : "ltr";
  }

  static translate(str, language) {
    if (translations.hasOwnProperty(language)) {
      const languageDb = translations[language];
      if (languageDb.hasOwnProperty(str)) {
        return languageDb[str];
      }

      return str;
    } else {
      const languageDb = translations[this.defaultLanguage];
      if (languageDb.hasOwnProperty(str)) {
        return languageDb[str];
      }

      return str;
    }
  }
}

export default Translator;
