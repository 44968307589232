import { fetchCustomQuestionsData } from "../../store/slices/customQuestionsData";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import { ReactComponent as BannerNoFriends } from "../../resources/images/bannerNoCustomQuestions.svg";
import { ReactComponent as QuestionIcon } from "../../resources/images/questionIcon.svg";
import { API, TELEGRAM } from "../../util/constants";
import { sendMetrics } from "../../util/metrics";
import Translator from "../../resources/localization/translator";

const CustomQuestionsChart = ({ dateRange }) => {
  const { chartDate, checkupsTotal } = useSelector(
    (state) => state.moodChartData
  );
  const {
    customQuestionsData,
    customQuestionsDataLoadingStatus,
    hasCustomQuestions,
  } = useSelector((state) => state.customQuestionsData);
  const { apiToken, language, viewedByAdmin } = useSelector(
    (state) => state.userParams
  );
  const dispatch = useDispatch();

  const legendPaddingPlugin = {
    id: "legendPaddingPlugin",
    beforeInit: function (chart, options) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        this.width = this.width + 30;
      };
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 30,
        top: 10,
        bottom: 10,
      },
    },
    plugins: {
      tooltip: { enabled: false },
      legend: {
        position: "right",
        align: "center",
        labels: {
          align: "end",
          usePointStyle: true,
          boxHeight: 9,
          pointStyleWidth: 12,
          font: {
            family: "manrope",
            size: 12,
            weight: "bold",
          },
        },
      },
      datalabels: {
        font: {
          family: "manrope",
          size: 12,
          weight: "bold",
        },
        formatter: function (value) {
          return value === 0 ? "" : value;
        },
      },
    },
  };

  useEffect(() => {
    dispatch(
      fetchCustomQuestionsData({
        chartDate: chartDate,
        dateRange: dateRange,
        apiToken: apiToken,
        lang: language,
      })
    );
  }, [chartDate, dateRange, apiToken]);

  const renderChart = (chartData, checkupsData) => {
    if (customQuestionsDataLoadingStatus === "loading")
      return (
        <section className={`mood-chart-skeleton_${dateRange}`}>
          <div className={`mood-chart-skeleton_${dateRange}-container`}>
            <div className={`mood-chart-skeleton_${dateRange}`}>
              <div
                className={`mood-chart-skeleton_${dateRange} skeleton`}
              ></div>
            </div>
          </div>
        </section>
      );
    if (
      customQuestionsDataLoadingStatus === "idle" &&
      Array.isArray(chartData)
    ) {
      if (chartData.length === 0 && checkupsData === 0) {
        const data = {
          labels: ["Да", "Нет"],
          datasets: [
            {
              data: [3, 2],
              backgroundColor: ["#B8B5FF", "#FCD893"],
              borderWidth: 0,
              datalabels: {
                anchor: "center",
                align: "center",
                color: "#fff",
              },
            },
          ],
        };

        return (
          <div key={`custom-question-chart-mock`} className="chart">
            <div className="chart-page-description">
              <div className="chart-page-description__content">
                <div className="chart-page-description__emoji">
                  <QuestionIcon />
                </div>
                <p className="chart-page-description__title">Все хорошо?</p>
              </div>
            </div>
            <div className="custom-question-chart">
              <Doughnut
                data={data}
                options={options}
                plugins={[legendPaddingPlugin]}
              />
            </div>
          </div>
        );
      }
      return chartData?.map((item, index) => {
        const data = {
          labels: item.data.map((dataItem) => dataItem.value),
          datasets: [
            {
              data: item.data.map((dataItem) => dataItem.count),
              backgroundColor: [
                "#B8B5FF",
                "#FCD893",
                "#FFC107",
                "#4CAF50",
                "#D27E00",
                "#FF9800",
                "#009688",
                "#03A9F4",
                "#FFEB3B",
                "#FF3DEC",
              ],
              borderWidth: 0,
              datalabels: {
                anchor: "center",
                align: "center",
                color: "#fff",
              },
            },
          ],
        };

        return (
          <div key={`custom-question-chart-${index}`} className="chart">
            <div className="chart-page-description">
              <div className="chart-page-description__content">
                <div className="chart-page-description__emoji">
                  <QuestionIcon />
                </div>
                <p className="chart-page-description__title">{item.title}</p>
              </div>
            </div>
            <div className="custom-question-chart">
              <Doughnut
                data={data}
                options={options}
                plugins={[legendPaddingPlugin]}
              />
            </div>
            <div id={`legend-container`}></div>
          </div>
        );
      });
    }
  };

  const openQuestionsMenu = async (data) => {
    try {
      const response = await API.openQuestionsMenu.openQuestionsMenu({
        api_token: apiToken,
      });

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return error;
    }
  };

  const sendData = () => {
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    sendMetrics("reachGoal", "click-create-custom-question-btn");
    if (!viewedByAdmin) {
      openQuestionsMenu();
    }
    setTimeout(() => {
      TELEGRAM.close();
    }, 0);
  };
  const clickHandler = () => {
    if (clickHandler.cantClick) return;
    clickHandler.cantClick = true;

    sendData();

    setTimeout(() => {
      clickHandler.cantClick = false;
    }, 500);
  };
  return (
    <div className={`chart-wrapper_no-bg${checkupsTotal < 2 ? "_empty" : ""}`}>
      <p className={`chart-text${checkupsTotal < 2 ? "_empty" : ""}`}>
        Чтобы увидеть статистику, необходимо пройти хотя бы 2 чекапа
      </p>
      <div
        className={`mood-chart-container_custom-questions${
          checkupsTotal < 2 ? "_blur" : ""
        }`}
      >
        <div className={`chart${dateRange === "month" ? "_month" : ""}`}>
          {renderChart(customQuestionsData.data, checkupsTotal)}
        </div>
      </div>
      {!hasCustomQuestions && checkupsTotal >= 2 ? (
        <div className="banner">
          <h1 className="banner-title">
            {Translator.translate(
              "BANNER_TITLE_CREATE_CUSTOM_QUESTION",
              language
            )}
          </h1>
          <div className="banner-content">
            <div>
              <div className="banner-content__item">
                <p className="banner-content__item__text">
                  {Translator.translate("BANNER_CONTENT_COFFE", language)}
                </p>
              </div>
              <div className="banner-content__item">
                <p className="banner-content__item__text">
                  {Translator.translate("BANNER_CONTENT_SPORT", language)}
                </p>
              </div>
              <div className="banner-content__item">
                <p className="banner-content__item__text">
                  {Translator.translate("BANNER_CONTENT_GRANDMA", language)}
                </p>
              </div>
            </div>
            <div className="banner-image_questions">
              <BannerNoFriends />
            </div>
          </div>
          <button className="banner-button" onClick={clickHandler}>
            <p className="banner-button__text">
              {Translator.translate("CREATE_QUESTION", language)}
            </p>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CustomQuestionsChart;
