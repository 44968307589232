import "./ScrollButton.scss";

import React from "react";
import { useSelector } from "react-redux";
import { TELEGRAM } from "../../../util/constants";
import cn from "classnames";

const ScrollButton = () => {
  const { backToTopVisible, target } = useSelector(
    (state) => state.datesWithCheckup
  );
  const { isOwnerDiary } = useSelector((state) => state.sharingData);
  const isDarkScheme = TELEGRAM.colorScheme === "dark";
  const scrollToTop = () => {
    if (!target) return;
    //target.scrollTo({ top: 0, behavior: "smooth" });
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
  };

  return (
    <button
      onClick={scrollToTop}
      style={{
        bottom: backToTopVisible
          ? isOwnerDiary
            ? "calc(5% + 60px)"
            : "5%"
          : "-50px",
      }}
      className={cn("scroll-button", {
        "scroll-button_dark": isDarkScheme,
      })}
    >
      <div className="scroll-button__content">
        <svg
          width="26"
          height="26"
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.6438 8.01843L9.66491 0.253456C9.56992 0.16129 9.46702 0.0961598 9.3562 0.0580645C9.24538 0.0193548 9.12665 0 9 0C8.87335 0 8.75462 0.0193548 8.6438 0.0580645C8.53298 0.0961598 8.43008 0.16129 8.33509 0.253456L0.332454 8.01843C0.110818 8.23349 0 8.5023 0 8.82489C0 9.14747 0.118734 9.42396 0.356201 9.65438C0.593668 9.88479 0.870712 10 1.18734 10C1.50396 10 1.781 9.88479 2.01847 9.65438L9 2.88018L15.9815 9.65438C16.2032 9.86943 16.4761 9.97696 16.8003 9.97696C17.1252 9.97696 17.4063 9.86175 17.6438 9.63134C17.8813 9.40092 18 9.13211 18 8.82489C18 8.51767 17.8813 8.24885 17.6438 8.01843Z"
            fill="var(--tg-theme-button-text-color, #F8F8F8)"
          />
        </svg>
      </div>
    </button>
  );
};

export default ScrollButton;
