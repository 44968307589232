import "./ErrorMessage.scss";
const ErrorMessage = () => {
  return (
    <section className="error-page">
      <p className="error-page__title">Что-то пошло не так</p>
      <svg
        width="172"
        height="152"
        viewBox="0 0 172 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="96.2568"
          cy="75.7432"
          r="75.7432"
          fill="var(--tg-theme-bg-color, #fdfdfd)"
        />
        <mask
          id="mask0_3985_331"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="20"
          y="0"
          width="152"
          height="152"
        >
          <circle cx="96.2568" cy="75.7432" r="75.7432" fill="#EDEEF7" />
        </mask>
        <g mask="url(#mask0_3985_331)">
          <path
            d="M41.7489 65.0319C41.7489 65.0319 47.2557 52.5014 49.7296 50.4377C52.2076 48.374 59.6376 44.6534 80.1441 44.1025C100.651 43.5516 152.665 48.4206 156.93 50.8996C161.196 53.3785 155.71 130.198 155.304 134.249C154.897 138.304 133.62 151.275 133.62 151.275L41.7489 65.0319Z"
            fill="#1D1938"
          />
          <path
            d="M141.427 80.6065C141.427 80.6065 142.519 67.5675 139.537 64.6351C136.153 61.3086 109.301 59.6135 102.366 59.4016C95.4319 59.1897 56.3463 57.6982 51.3054 58.961C46.2645 60.2238 41.8506 62.7451 41.0119 67.1564C40.1731 71.5678 36.598 119.698 35.5474 127.05C34.4969 134.403 35.5474 149.959 39.9571 153.112C44.3668 156.264 76.3021 157.315 82.6011 157.523C88.9043 157.735 122.725 155.841 126.296 154.582C129.867 153.319 133.437 152.061 135.539 149.12C137.64 146.179 138.898 123.482 139.529 114.863C140.164 106.248 141.427 80.6065 141.427 80.6065Z"
            fill="#1D1938"
            stroke="#B6B2D9"
            strokeWidth="0.377365"
            strokeMiterlimit="10"
          />
          <path
            d="M99.1933 52.8573L100.748 35.3815L102.027 35.4493L100.799 53.1158C100.799 53.1158 100.388 53.4421 99.8965 53.3615C99.4051 53.281 99.1933 52.8573 99.1933 52.8573Z"
            fill="#B6B2D9"
          />
          <path
            d="M102.302 26.6615C102.302 26.6615 97.4438 26.7844 97.2574 30.5389C97.0752 34.251 99.1636 35.7088 101.133 35.9545C103.103 36.2003 106.615 34.446 106.729 31.4627C106.916 26.7844 102.302 26.6615 102.302 26.6615Z"
            fill="#6764AB"
          />
          <path
            d="M138.398 82.0903C138.398 82.0903 139.474 69.4538 136.741 67.0639C133.226 63.9874 107.89 62.5508 101.379 62.3559C94.8685 62.161 58.4557 60.5676 53.7156 61.7542C48.9755 62.9407 44.8327 65.3095 44.0448 69.4538C43.2569 73.6024 39.8977 118.822 38.9107 125.733C37.9237 132.645 38.9107 147.256 43.0578 150.218C47.2048 153.18 77.2085 154.167 83.1305 154.366C89.0524 154.566 120.831 152.786 124.19 151.604C127.545 150.417 130.9 149.235 132.874 146.468C134.848 143.705 136.034 122.377 136.623 114.279C137.212 106.181 138.398 82.0903 138.398 82.0903Z"
            fill="#B6B2D9"
            stroke="#1D1938"
            strokeWidth="0.377365"
            strokeMiterlimit="10"
          />
          <path
            d="M86.5147 73.4696C86.5147 73.4696 85.2015 67.0284 81.2578 68.2107C77.3141 69.393 75.3909 77.6097 75.4587 83.0974C75.4926 85.856 78.7628 88.0596 81.5247 87.2714C84.2823 86.4832 85.8708 83.9618 86.5571 80.7074C87.2645 77.3512 86.5147 73.4696 86.5147 73.4696Z"
            fill="#EDEEF7"
          />
          <path
            d="M80.4696 81.4263C80.4696 81.4263 80.2197 80.689 78.9362 81.1212C77.6526 81.5577 77.6865 82.8544 77.7713 84.3036C77.856 85.7529 78.5507 86.6004 79.5123 86.4139C80.4738 86.2317 80.8763 85.6088 81.0245 84.4477C81.1728 83.2908 80.4696 81.4263 80.4696 81.4263Z"
            fill="#1D1938"
          />
          <path
            d="M105.64 74.5365C105.64 74.5365 104.327 68.0954 100.384 69.2777C96.4398 70.4599 94.5167 78.6766 94.5844 84.1643C94.6183 86.9229 97.8885 89.1265 100.65 88.3383C103.408 87.5501 104.997 85.0287 105.683 81.7743C106.39 78.4224 105.64 74.5365 105.64 74.5365Z"
            fill="#EDEEF7"
          />
          <path
            d="M99.587 81.9146C99.587 81.9146 99.4048 81.1561 98.0874 81.4696C96.77 81.7832 96.6811 83.0799 96.6303 84.5292C96.5794 85.9827 97.1936 86.8853 98.1637 86.7921C99.138 86.6988 99.5955 86.1183 99.8539 84.9784C100.112 83.8385 99.587 81.9146 99.587 81.9146Z"
            fill="#1D1938"
          />
          <path
            d="M89.6919 90.7884C89.6919 90.7884 90.0646 88.5848 88.6498 88.7755C87.235 88.9705 86.6758 92.5682 87.8831 92.7928C89.353 93.064 89.6919 90.7884 89.6919 90.7884Z"
            fill="#1D1938"
          />
          <path
            d="M116.9 67.8983L116.476 74.8904L120.501 75.3141L121.348 68.2542L116.9 67.8983Z"
            fill="#6764AB"
          />
          <path
            d="M133.208 74.4979L133.462 71.646L131.472 71.468L131.679 69.1501L116.904 67.8279L116.268 74.9217L130.849 76.2226L131.014 74.3665L133.208 74.4979Z"
            stroke="#1D1938"
            strokeWidth="0.452838"
            strokeMiterlimit="10"
          />
          <path
            d="M94.4442 99.6051C94.4442 99.6051 92.9446 96.8634 90.7419 99.4695C88.5392 102.076 91.0723 106.47 93.3301 104.614C96.0708 102.355 94.4442 99.6051 94.4442 99.6051Z"
            fill="#1D1938"
          />
          <path
            d="M49.4833 104.717L48.4878 132.024C48.3946 134.592 50.3729 136.762 52.9357 136.901L62.2718 137.423C64.8346 137.567 67.0416 135.63 67.2322 133.066L69.3163 105.153C69.5197 102.403 67.3339 100.064 64.5762 100.085L54.1472 100.17C51.631 100.195 49.5723 102.196 49.4833 104.717Z"
            fill="#1D1938"
          />
          <path
            d="M152.232 83.8989C152.232 83.8989 149.957 82.3056 147.454 83.4455C144.95 84.5854 143.358 88.9078 144.268 91.8656C145.179 94.8234 144.154 102.697 142.565 105.884C140.973 109.07 132.505 120.27 122.262 126.194C111.155 132.618 96.6258 135.208 89.8948 134.881C83.0875 134.551 74.5689 133.216 70.5828 129.724C66.7831 126.398 62.043 118.842 59.997 118.842C58.1332 118.842 60.2766 123.469 60.5604 124.067C60.5646 124.08 60.5477 124.088 60.5392 124.08C60.0182 123.436 56.0787 118.897 55.0832 120.999C54.5961 122.029 55.9897 124.885 56.3625 125.609C56.3795 125.639 56.3413 125.665 56.3159 125.643C55.5831 124.902 52.7407 121.821 51.4445 123.503C50.2796 125.016 53.0372 129.419 62.3649 136.932C71.6926 144.446 85.3453 146.263 96.4903 146.035C107.635 145.806 122.203 141.039 132.67 132.618C143.137 124.198 147.225 120.999 151.546 113.261C155.867 105.523 155.413 90.9587 155.185 88.4543C154.96 85.9457 152.232 83.8989 152.232 83.8989Z"
            fill="#B6B2D9"
            stroke="#1D1938"
            strokeWidth="0.377365"
            strokeMiterlimit="10"
          />
          <path
            d="M56.431 102.484C56.431 102.484 52.3983 102.438 52.034 103.429C51.6697 104.421 51.0894 109.459 52.4153 109.62C53.7411 109.781 57.5324 110.108 58.0026 109.37C58.4728 108.633 58.4643 102.764 57.8416 102.637C57.2189 102.51 56.431 102.484 56.431 102.484Z"
            fill="#EDEEF7"
          />
          <path
            d="M54.219 103.475C54.219 103.475 53.0032 103.004 52.5711 103.915C52.1433 104.814 52.5118 105.39 52.9736 105.653C53.4353 105.916 54.4858 105.843 54.8205 105.119C55.3457 103.979 54.219 103.475 54.219 103.475Z"
            fill="#1D1938"
          />
          <path
            d="M56.9343 106.629C56.9343 106.629 55.7186 106.158 55.2865 107.069C54.8587 107.968 55.2272 108.544 55.689 108.807C56.1507 109.069 57.2012 108.997 57.5359 108.273C58.0654 107.133 56.9343 106.629 56.9343 106.629Z"
            fill="#1D1938"
          />
        </g>
        <path
          d="M37.1743 108.068C36.9583 108.047 23.4412 106.483 16.2357 104.5C-0.716809 99.8341 -0.877777 86.3883 3.20574 79.4132C6.20907 74.2857 19.0654 63.8485 42.6812 57.3395C66.2969 50.8306 75.201 51.7036 75.7898 52.4112C77.1284 54.0215 72.9898 55.3479 72.5789 55.5343C72.5747 55.5386 72.5747 55.5428 72.5832 55.5428C73.0449 55.5004 78.4628 55.0385 78.5221 56.581C78.5814 58.0726 74.0785 58.8735 73.4007 58.9837C73.3923 58.9837 73.3923 59.0007 73.4007 59.0007C74.07 59.0219 78.3992 59.2337 77.175 61.1025C75.8534 63.1281 71.4988 63.1493 68.5505 63.6069C65.5048 64.0815 53.2161 65.6452 45.5913 67.7216C37.3438 69.9719 30.2019 71.6245 24.2587 75.201C17.4133 79.32 10.7077 81.693 14.0372 90.6259C16.0197 95.9398 37.9538 96.7576 38.1147 96.7703"
          fill="#B6B2D9"
        />
        <path
          d="M37.1743 108.068C36.9583 108.047 23.4412 106.483 16.2357 104.5C-0.716809 99.8341 -0.877777 86.3883 3.20574 79.4132C6.20907 74.2857 19.0654 63.8485 42.6812 57.3395C66.2969 50.8306 75.201 51.7036 75.7898 52.4112C77.1284 54.0215 72.9898 55.3479 72.5789 55.5343C72.5747 55.5386 72.5747 55.5428 72.5832 55.5428C73.0449 55.5004 78.4628 55.0385 78.5221 56.581C78.5814 58.0726 74.0785 58.8735 73.4007 58.9837C73.3923 58.9837 73.3923 59.0007 73.4007 59.0007C74.07 59.0219 78.3992 59.2337 77.175 61.1025C75.8534 63.1281 71.4988 63.1493 68.5505 63.6069C65.5048 64.0815 53.2161 65.6452 45.5913 67.7216C37.3438 69.9719 30.2019 71.6245 24.2587 75.201C17.4133 79.32 10.7077 81.693 14.0372 90.6259C16.0197 95.9398 37.9538 96.7576 38.1147 96.7703"
          stroke="#1D1938"
          strokeWidth="0.377365"
          strokeMiterlimit="10"
        />
      </svg>
      <section className="error-page__footer">
        <button
          className="error-page__button_reload"
          onClick={() => window.location.reload()}
        >
          <p>Обновить страницу</p>
        </button>
        <a
          href="https://t.me/monday4me_support"
          className="error-page__button_support"
        >
          <p>Написать в поддержку</p>
        </a>
      </section>
    </section>
  );
};

export default ErrorMessage;
