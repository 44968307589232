import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../../util/constants";
import { AxiosResponse } from "axios";

const initialState: UserParams = {
  apiToken: null,
  language: "ru",
  viewedByAdmin: null,
  colorScheme: "light",
  themeParams: {},
  shareToken: null,
  startPage: null,
  firstTimeOpen: true,
};

const UserParamsSlice = createSlice({
  name: "userParams",
  initialState,
  reducers: {
    setViewedByAdmin: (state, { payload }) => {
      state.viewedByAdmin = payload;
    },
    setApiToken: (state, { payload }) => {
      state.apiToken = payload;
    },
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
    setColorScheme: (state, { payload }) => {
      state.colorScheme = payload;
    },
    setThemeParams: (state, { payload }) => {
      state.themeParams = payload;
    },
    setShareToken: (state, { payload }) => {
      state.shareToken = payload;
    },
    setStartPage: (state, { payload }) => {
      state.startPage = payload;
      state.firstTimeOpen = false;
    },
    setFirstTimeOpen: (state, { payload }) => {
      state.firstTimeOpen = payload;
    },
  },
});

const { actions, reducer } = UserParamsSlice;

export default reducer;
export const {
  setColorScheme,
  setApiToken,
  setLanguage,
  setViewedByAdmin,
  setThemeParams,
  setShareToken,
  setStartPage,
  setFirstTimeOpen,
} = actions;
