import { useNavigate } from "react-router-dom";
import { TELEGRAM } from "../../../util/constants";
import { sendMetrics } from "../../../util/metrics";
import "./GoBackButton.scss";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Translator from "../../../resources/localization/translator";
import * as cn from "classnames";
import { setQueryToggle } from "../../../store/slices/calendarData";

interface ComponentProps {
  // title: string;
}
interface RootState {
  userParams: UserParams;
}
const GoBackButton: React.FC<ComponentProps> = (
  {
    /* Деструктуризация пропсов */
  }
) => {
  const { apiToken, language, viewedByAdmin } = useSelector(
    (state: UserParamsSlice) => state.userParams
  );
  const { datesWithCheckup } = useSelector(
    (state: any) => state.datesWithCheckup
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDarkScheme = TELEGRAM.colorScheme === "dark";

  const goBackAndResetData = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(
        `/calendar/?token=${apiToken}&language=${language}&viewed_by_admin=${viewedByAdmin}`
      );
    }
    if (Object.keys(datesWithCheckup).length !== 0) {
      dispatch(setQueryToggle(false));
    }
    TELEGRAM.HapticFeedback.impactOccurred("heavy");
    if (typeof window.ym != "undefined") {
      sendMetrics("reachGoal", "click-go-back-btn");
    }
  };
  return (
    <button
      className={cn("go-back-button", {
        "go-back-button_dark": isDarkScheme,
      })}
      onClick={goBackAndResetData}
    >
      {Translator.translate("GO_BACK", language)}
    </button>
  );
};

export default GoBackButton;
