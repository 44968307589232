export const imagePlugin = {
  id: "imagePlugin",
  beforeDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      data,
      scales: { x, y },
    } = chart;
    ctx.save();
    data?.datasets[0]?.image?.forEach((imageLink, index) => {
      const logo = new Image();
      logo.src = imageLink;
      const yPixel = y.getPixelForValue(index + 1);

      // Проверяем, находится ли иконка выше верхней границы canvas
      if (yPixel - 15 < 0) {
        // Если иконка выше границы, смещаем ее ниже границы на 15 пикселей
        ctx.drawImage(logo, 0, 15, 25, 25);
      } else {
        ctx.drawImage(logo, 0, yPixel - 15, 25, 25);
      }
    });
  },
};
