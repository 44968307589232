import sub from "date-fns/sub";
import add from "date-fns/add";
import isBefore from "date-fns/isBefore";
import { API, TELEGRAM } from "./constants";
import { sendMetrics } from "./metrics";
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subWeeks,
} from "date-fns";

export const parseData = (dataFromServer, state) => {
  const result = JSON.parse(JSON.stringify(state));

  Object.keys(dataFromServer).forEach((date) => {
    const [year, month] = date.split("-");
    if (!year || !month) return;

    [year, month].reduce((curr, key) => curr[key] || (curr[key] = {}), result);
    result[year][month][date] = dataFromServer[date];
  });

  return result;
};

export const getFirstCheckupDate = (dataFromServer) => {
  const date = new Date(dataFromServer.first_checkup_date);
  return date;
};

export const getPaginationMonthCount = (firstDate, secondDate) => {
  if (isBefore(firstDate, secondDate)) {
    return 12; //36
  }
  return 12;
};

export const getDatesArray = (date, countOfMonth, isInitial = false) => {
  let month = date;
  if (isInitial) {
    month = getNextMonth(date, 1);
  }
  return Array.from(new Array(countOfMonth)).map((_, index) => {
    return getPrevMonthCounter(month, index);
  });
};
export const getPrevMonthCounter = (date, shift) => {
  const result = sub(date, {
    months: shift,
  });
  return result;
};
export const getPrevMonth = (date, shift) => {
  const result = sub(date, {
    months: shift,
  });
  return result;
};

export const getNextMonth = (date, shift) => {
  const result = add(date, {
    months: shift,
  });
  return result;
};
export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
export function throttle(callee, timeout) {
  let timer = null;

  return function perform(...args) {
    if (timer) return;

    timer = setTimeout(() => {
      callee(...args);

      clearTimeout(timer);
      timer = null;
    }, timeout);
  };
}

export const startCheckup = async (apiToken, checkupDate) => {
  try {
    const response = await API.startCheckup.startCheckupStartCheckupPost({
      api_token: apiToken,
      target_date: checkupDate,
    });

    return response;
  } catch (err) {
    let error = err;
    if (!error.response) throw err;

    return error;
  }
};

export const addNote = async (apiToken, checkupDate) => {
  try {
    const response = await API.addNote.addNoteAddNotePost({
      api_token: apiToken,
      target_date: checkupDate,
    });

    return response;
  } catch (err) {
    let error = err;
    if (!error.response) throw err;

    return error;
  }
};

///charts

//На входе currentDate - обьект Date(), на выходе - массив обьектов Date()
export const getCurrentAndLastWeekDays = (currentDate) => {
  const currentWeekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
  const lastWeekStart = subWeeks(currentWeekStart, 1);
  const lastWeekDays = [];
  for (let i = 0; i < 7; i++) {
    const date = addDays(lastWeekStart, i);
    lastWeekDays.push(date);
  }
  const currentWeekDays = [];
  for (let i = 0; i < 7; i++) {
    const date = addDays(currentWeekStart, i);
    currentWeekDays.push(date);
  }
  return [...lastWeekDays, ...currentWeekDays];
};
//На входе date - обьект Date(), range - строка временного интервала, на выходе - массив обьектов Date() от старта до конца
//временного интервала в который входит date
export const getDateRange = (date, range) => {
  switch (range) {
    case "week":
      const weekStart = startOfWeek(date, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(date, { weekStartsOn: 1 });
      return eachDayOfInterval({ start: weekStart, end: weekEnd });
    case "lastAndCurrentWeek":
      return getCurrentAndLastWeekDays(date);
    case "month":
      const monthStart = startOfMonth(date);
      const monthEnd = endOfMonth(date);
      return eachDayOfInterval({ start: monthStart, end: monthEnd });
    default:
      throw new Error("Invalid range argument");
  }
};
//На входе currentDate - Date(), dateRange - строка, mode - строка, на выходе Date() начала или конца временного интервала dateRange
export const getDatesInterval = (currentDate, dateRange, mode) => {
  switch (dateRange) {
    case "week":
      if (mode === "start")
        return startOfWeek(currentDate, { weekStartsOn: 1 });
      else return endOfWeek(currentDate, { weekStartsOn: 1 });
    case "lastAndCurrentWeek":
      const currentWeekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
      const lastWeekStart = subWeeks(currentWeekStart, 1);
      if (mode === "start") return lastWeekStart;
      else return endOfWeek(currentDate, { weekStartsOn: 1 });
    case "month":
      if (mode === "start") return startOfMonth(currentDate);
      else return endOfMonth(currentDate);
    default:
      return [currentDate, currentDate];
  }
};
