import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import formatISO from "date-fns/formatISO";
import {
  DAYS_IN_WEEK,
  DAYS_IN_MONTH,
  WEEK_DAYS_FROM_MONDAY,
  MONTHS,
} from "./constants";
import { format } from "date-fns";

export function isLeapYear(year) {
  return !(year % 4 || (!(year % 100) && year % 400));
}

function getDaysInMonth(date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  const daysInMonth = DAYS_IN_MONTH[month];

  if (isLeapYear(year) && month === MONTHS.February) {
    return daysInMonth + 1;
  } else {
    return daysInMonth;
  }
}

export function getDayOfWeek(date) {
  const dayOfWeek = date.getDay();

  return WEEK_DAYS_FROM_MONDAY[dayOfWeek];
}

export function getMonthData(year, month) {
  const result = [];
  const date = new Date(year, month);
  const daysInMonth = getDaysInMonth(date);
  const monthStartsOn = getDayOfWeek(date);
  let day = 1;

  for (let i = 0; i < (daysInMonth + monthStartsOn) / DAYS_IN_WEEK; i++) {
    result[i] = [];

    for (let j = 0; j < DAYS_IN_WEEK; j++) {
      if ((i === 0 && j < monthStartsOn) || day > daysInMonth) {
        result[i][j] = undefined;
      } else {
        result[i][j] = new Date(year, month, day++);
      }
    }
  }

  return result;
}

export const areDatesEqual = (firstDate, secondDate) => {
  if (!firstDate || !secondDate) return false;

  return firstDate.setHours(0, 0, 0, 0) === secondDate.setHours(0, 0, 0, 0);
};

export const areDatesEqualObj = (firstDate, object) => {
  const year = getYear(firstDate);
  const month = getMonth(firstDate) + 1;
  const dateISO = formatISO(firstDate, { representation: "date" });
  if (
    typeof object[year] !== "undefined" &&
    typeof object[year][`${month < 10 ? "0" : ""}${month}`] !== "undefined"
  ) {
    const monthData = object[year][`${month < 10 ? "0" : ""}${month}`];
    if (monthData) {
      for (let day in monthData) {
        const dayData = monthData[day];
        if (dateISO === day) {
          return dayData;
        }
      }
    }
  }
};

//На входе массив с обьектами Date(), на выходе массив строк вида "31.05"
export const formatDates = (array, dateRange) => {
  // if (dateRange === "lastAndCurrentWeek") {
  //   return array.map((element, index) => {
  //     if (index % 2 === 0) {
  //       return format(element, "dd.MM");
  //     }
  //     return "";
  //   });
  // }
  return array.map((element) => {
    const result = format(element, "dd.MM");
    return result;
  });
};

//На входе обьект Date(), на выходе - строка вида "2023-05-31"
export const getISODate = (date) => {
  const result = formatISO(date, { representation: "date" });

  return result;
};

export const getLocalMonthNames = (dateLocal, format = "long") => {
  let localeMonth = dateLocal.toLocaleString("en", { month: format });

  return localeMonth;
};

//На входе индекс дня недели, на выходе - строка с названием дня
export const parseWeekDay = (day) => {
  switch (day) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
  }
};
