import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { API } from "../../../util/constants";

const initialState = {
  emotionsStatsDataLoadingStatus: "loading",
  emotionsStatsData: [],
};

export const fetchEmotionStatsData = createAsyncThunk(
  "emotionsStatsChartData/fetchEmotionStatsData",
  async (
    { chartDate, dateRange, apiToken, lang },
    { dispatch, rejectWithValue }
  ) => {
    let start, end;
    switch (dateRange) {
      case "week":
        start = startOfWeek(chartDate, { weekStartsOn: 1 });
        end = endOfWeek(chartDate, { weekStartsOn: 1 });
        break;
      case "lastAndCurrentWeek":
        start = startOfWeek(subWeeks(chartDate, 1), { weekStartsOn: 1 });
        end = endOfWeek(chartDate, { weekStartsOn: 1 });
        break;
      case "month":
        start = startOfMonth(chartDate);
        end = endOfMonth(chartDate);
        break;
      default:
        start = chartDate;
        end = chartDate;
        break;
    }
    const dateFrom = format(start, "yyyy-MM-dd");
    const dateTo = format(end, "yyyy-MM-dd");

    try {
      const response =
        await API.emotionsStats.getEmotionSourceCorrelationEmotionsStatsGet({
          dateFrom: dateFrom,
          dateTo: dateTo,
          api_token: apiToken,
          lang: lang,
        });

      return response;
    } catch (err) {
      let error = err;
      if (!error.response) throw err;

      return rejectWithValue(error.response.data);
    }
  }
);

const ChartSlice = createSlice({
  name: "emotionsStatsChartData",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmotionStatsData.pending, (state) => {
        state.emotionsStatsDataLoadingStatus = "loading";
      })
      .addCase(fetchEmotionStatsData.fulfilled, (state, action) => {
        state.emotionsStatsDataLoadingStatus = "idle";
        state.emotionsStatsData = action.payload;
        // console.log(action.payload);
      })
      .addCase(fetchEmotionStatsData.rejected, (state) => {
        state.emotionsStatsDataLoadingStatus = "error";
      })
      .addDefaultCase(() => {});
  },
});

const { actions, reducer } = ChartSlice;

export default reducer;
export const { setChartDate, setWithFriends } = actions;
